<template>
  <ContainerComponent>
    <template #right>
      <v-flex
        fill-height
        class="px-3 px-md-6 pt-3 pt-md-5 pt-lg-7 text-center"
      >
        <v-tabs
          v-show="false"
          v-model="tab"
          grow
          dark
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="fill-height background"
          touchless
        >
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            class="fill-height"
          >
            <v-row class="text-left">
              <v-col class="py-lg-1 pb-sm-1 pb-0">
                <h3
                  class="primary--text text-sm-center mb-lg-2 mb-md-0 mb-1"
                  :class="titleResponsiveSize"
                >
                  {{ item.title }}
                </h3>
                <p class="text text-l-large-regular text-sm-center mb-0">
                  {{ item.content }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ConfirmPasswordComponent
                  :confirm="confirmPassword"
                  :email="email"
                  @new-password-completed="newPasswordCompleted"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col 
                cols="12"
                class="actions"
              >
                <v-card-actions class="px-0 mb-lg-3 pb-lg-4 container-actions">
                  <v-btn
                    ripple
                    block
                    large
                    depressed
                    color="primary"
                    class="heading heading-s-small white--text shadow-dark-button"
                    @click="confirmNewPassword"
                  >
                    Create password
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="px-0 pb-4 mb-lg-3 container-actions">
                  <v-btn
                    ripple
                    block
                    large
                    color="white"
                    class="heading heading-s-small text-capitalize shadow-light-button"
                    @click="handleCancelClick"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </template>
  </ContainerComponent>
</template>

<script>
import ConfirmPasswordComponent from "@/components/common/ConfirmPasswordComponent";
// resolves the warning: [mini-css-extract-plugin] Conflicting order...
const ContainerComponent = () => import(/* webpackChunkName: "ContainerComponent" */ "@/components/common/ContainerComponent");
import { mapActions } from "vuex";
export default {
  components: { ConfirmPasswordComponent, ContainerComponent },
  data: () => ({
    tab: 0,
    email: null,
    password: null,
    confirmPassword: 0,
    items: [
      {
        tab: 0,
        title: "New password",
        content:"Please, enter your new password",
      },
    ],
  }),
  computed: {
    titleResponsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "heading heading-m-large";
        case "lg":
          return "heading heading-l-large";
        default:
          return "heading heading-l-small";
      }
    }
  },
  mounted() {
    this.email =  this.$route.params.email;
    this.verifyResetPasswordToken({ email: this.email, token: this.$route.params.token });
  },
  methods: {
    ...mapActions('auth', ['verifyResetPasswordToken']),
    ...mapActions('auth', ['logout']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    async performLogout() {
      await this.logout();
      document.cookie = "";
      this.hideTopMenu(false);
      this.setTopMenuTitle('');
      this.$router.push({ name: "home" });
    },
    newPasswordCompleted(newPassword) {
      this.password = newPassword;
      this.performLogout();
    },
    async validatePassword() {
      this.confirmPassword++;
    },
    confirmNewPassword() {
      this.validatePassword();
    },
    handleCancelClick() {
      this.performLogout();
    },
  },
}
</script>
<style scoped>
.actions {
    padding: 18px;
  }

.container-actions {
  flex-wrap: wrap;
  bottom: 15px;
  margin-left: 0px;
  width: 100%;
}

@media (min-width: 1440px)  and (orientation: landscape) {
  .actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .container-actions { 
     width: 48%;
  }
}
</style>