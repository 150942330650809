<template>
  <div>
    <PasswordComponent
      :label="'Password'"
      :placeholder="'Enter your new password'"
      @new-password="newPassword"
    />
    <PasswordComponent
      :label="'Repeat password'"
      :placeholder="'Enter password again'"
      @new-password="newConfirmedPassword"
    />
  </div>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
import PasswordComponent from '@/components/common/PasswordComponent';
import { mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidatorComponent, PasswordComponent },
  props: ['confirm','email'],
  data: () => ({
    password: '',
    passwordConfirmed: '',
    rules: ValidatorComponent.data().rules,
    error: false,
    resetChecker: null,
  }),
  watch: {
    password: function(newValue) {
      if (this.passwordConfirmed !== '' && newValue !== this.passwordConfirmed && !this.error) {
        this.showNotMatchingPasswordError();
      }
    },
    passwordConfirmed: function(newValue) {
      if (this.password !== '' && newValue !== this.password && !this.error) {
        this.showNotMatchingPasswordError();
      }
    },
    confirm: function(isValid) {
      if (isValid > 0 && this.password === this.passwordConfirmed) {
        this.confirmPassword();
      } else {
        this.showNotMatchingPasswordError();
      }
    }
  },
  mounted() {
    this.resetChecker = this.$_debounce(function(){
      this.error = false;
    }, 2500);
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    confirmPassword() {
      const changePasswordParams = { 'email': this.email, 'password': this.password };

      this.changePassword(changePasswordParams).then( response => {
        this.$toasted.global.showSuccess({ message: response.data.message });
        this.$emit('new-password-completed', this.password);
      }).catch(error => {
        this.$toasted.global.showError({message: error.response.data?.message || error.message});
      });
    },
    showNotMatchingPasswordError() {
      this.error = true;
      this.resetChecker();
      this.$toasted.global.showError({ message: 'Password does not match' });
    },
    newPassword(newPassword) {
      this.password = newPassword;
    },
    newConfirmedPassword(newPasswordConfirmed) {
      this.passwordConfirmed = newPasswordConfirmed;
    }
  },
}
</script>
